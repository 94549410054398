import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    ReactFlow,
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Background,
    reconnectEdge,
    useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./index.css";
import { Sidebar } from "./side-bar/SideBar";
import { Colors } from "constants/colors";
import { selectAppliances } from "store/appliancesSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectProject, updateEdges, updateNodes } from "store/projectSlice";
import { CustomNodeHandle, TypeConnections } from "shared/components";
import { Spin } from "antd";
import { Device, INode, Project, Template } from "shared/interfaces";
import { selectSequences } from "store/sequencesSlice";
import { ControlPanel } from "./ControlPanel";
import { useDnD } from "./DndProvider";

export enum ConnectType {
    PNEUMATIC = "#59DADA",
    LIQUID = "#4054C9",
    SENSOR_DATA = "#F5A42A",
    VALVE_DATA = "#D6392B",
}

const MAX_HISTORY = 15;
export const HISTORY_KEY = "reactflow-history";
export const HISTORY_INDEX_KEY = "reactflow-currentHistoryIndex";

export const ProviderFlow = () => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectAppliances);
    const { project, simulation, fetchStatus } = useAppSelector(selectProject);
    const { activeSequences } = useAppSelector(selectSequences);
    const reactFlowWrapper = useRef<any>(null);
    const [nodes, setNodes, onNodesChange] = useNodesState<any>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);
    const isLoading = fetchStatus === "init" || fetchStatus === "fetching";
    const { id, template } = project || {};
    const { screenToFlowPosition } = useReactFlow();
    const [type] = useDnD();
    const edgeReconnectSuccessful = useRef(true);

    useEffect(() => {
        if (project) {
            const { nodes = [], edges = [] } = template || {};
            if (nodes) {
                setNodes(nodes);
            }
            if (edges) {
                setEdges(edges);
            }
        }
    }, [project]);

    const onConnect = (params: any) => {
        const { sourceConnect = -1, targetConnect = "" } = (() => {
            try {
                if (!Array.isArray(data)) {
                    console.error("data is not an array");
                    return {};
                }
                if (template && !Array.isArray(template.nodes)) {
                    console.error("template.nodes is not an array");
                    return {};
                }
                const combinedArray = [...data, ...(template?.nodes || [])];
                const result = connection(params, combinedArray);
                return result;
            } catch (error) {
                console.error(
                    "Error in combining arrays or connection:",
                    error
                );
                return {};
            }
        })();

        let foundTarget = edges.find(
            (item) =>
                item.target === params.target &&
                item.targetHandle === params.targetHandle
        );
        let foundSource = edges.find(
            (item) =>
                item.source === params.source &&
                item.sourceHandle === params.sourceHandle
        );
        if (foundTarget || foundSource) {
            console.log(
                "Connection not allowed: Only one connection per node is allowed."
            );
            return;
        }

        if (sourceConnect !== undefined && sourceConnect === targetConnect) {
            const colors = [
                "",
                ConnectType.PNEUMATIC,
                ConnectType.LIQUID,
                ConnectType.SENSOR_DATA,
                ConnectType.VALVE_DATA,
            ];
            const par = {
                ...params,
                type: "smoothstep",
                style: {
                    stroke: colors[sourceConnect],
                    strokeWidth: 2,
                },
            };
            dispatch(updateEdges(edges?.concat(par)));
            setEdges((els) => addEdge(par, els));
        } else {
            console.log("invalid connection");
        }
    };

    const onDragOver = (event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    const onNodeDragStop = (e: any, node: Project) => {
        const replaceObjectById = (array: any, newObject: any) => {
            return array.map((obj: any) =>
                obj?.id === newObject?.id ? newObject : obj
            );
        };
        dispatch(updateNodes(replaceObjectById(nodes, node)));
        updateHistory(replaceObjectById(nodes, node), edges);
    };

    const addBorder = (node: Project) =>
        setNodes((nds) =>
            nds.map((n) =>
                n.id === node.id
                    ? {
                          ...n,
                          style: {
                              ...n.style,
                              backgroundColor: "#eaeaea",
                              outline: "1px solid #b9b9b9",
                          },
                      }
                    : {
                          ...n,
                          style: {
                              ...n.style,
                              backgroundColor: "",
                              outline: "1px solid transparent",
                          },
                      }
            )
        );

    const onNodeDragStart = (e: any, node: Project) => {
        addBorder(node);
    };

    const onDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            if (!type) {
                return;
            }
            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            const node = data.find((item) => +item.id === +type);
            const { id, connectors, image, width, height, name } = node || {};
            const newNode = {
                id: Math.floor((1 + Math.random()) * 0x10000).toString(),
                type,
                position,
                data: { label: name },
                node: {
                    connectors: connectors,
                    image: image,
                },
                idDevice: id,
                width: width,
                height: height,
            };

            setNodes((nds) => nds.concat(newNode));
            dispatch(updateNodes(nodes.concat(newNode)));
            updateHistory([...nodes, newNode], edges);
        },
        [screenToFlowPosition, type, nodes]
    );

    useEffect(() => {
        if (!activeSequences.length) {
            const isAnimated = (type: ConnectType) =>
                type === ConnectType.LIQUID || type === ConnectType.PNEUMATIC;
            setEdges((eds) =>
                eds.map((edge) => ({
                    ...edge,
                    animated: isAnimated(edge.style.stroke)
                        ? simulation
                        : false,
                }))
            );
        } else {
            const animateEdge = (outputId: any) => {
                return new Promise((resolve: any) => {
                    setEdges((eds) =>
                        eds.map((edge) => {
                            console.log(
                                11111,
                                outputId.includes(+edge.sourceHandle)
                            );
                            console.log("outputId", outputId);
                            console.log("edge.sourceHandle", edge.sourceHandle);
                            return outputId.includes(+edge.sourceHandle)
                                ? { ...edge, animated: true }
                                : edge;
                        })
                    );
                    resolve();
                });
            };

            const delay = (ms: number) =>
                new Promise((resolve) => setTimeout(resolve, ms * 1000));

            const animateDependencies = async () => {
                for (const dependency of activeSequences) {
                    if (dependency.type === "device") {
                        await animateEdge(dependency.outputId);
                    } else if (
                        dependency.type === "condition" &&
                        dependency.typeCondition === "wait"
                    ) {
                        await delay(dependency.timer);
                    }
                }
            };
            if (activeSequences.length > 0) {
                animateDependencies();
            } else {
                setEdges((eds) =>
                    eds.map((edge) => ({
                        ...edge,
                        animated: false,
                    }))
                );
            }
        }
    }, [simulation, activeSequences]);

    const nodeHandlers = useMemo(
        () => createNodeHandlers(data, template),
        [data, template]
    );

    const onReconnectStart = useCallback(() => {
        edgeReconnectSuccessful.current = false;
    }, []);

    const onReconnect = useCallback((oldEdge: any, newConnection: any) => {
        edgeReconnectSuccessful.current = true;
        const { sourceConnect = -1, targetConnect = "" } = (() => {
            try {
                if (!Array.isArray(data)) {
                    console.error("data is not an array");
                    return {};
                }
                if (template && !Array.isArray(template.nodes)) {
                    console.error("template.nodes is not an array");
                    return {};
                }
                const combinedArray = [...data, ...(template?.nodes || [])];
                const result = connection(newConnection, combinedArray);
                return result;
            } catch (error) {
                console.error(
                    "Error in combining arrays or connection:",
                    error
                );
                return {};
            }
        })();
        const isConnect =
            sourceConnect !== undefined && sourceConnect === targetConnect;
        isConnect &&
            setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    }, []);

    const onReconnectEnd = useCallback((_: any, edge: any) => {
        if (!edgeReconnectSuccessful.current) {
            setEdges((eds) => {
                const edg = eds.filter((e) => e.id !== edge.id);
                dispatch(updateEdges(edg));
                return edg;
            });
        }

        edgeReconnectSuccessful.current = true;
    }, []);

    const [history, setHistory] = useState(() => {
        const savedHistory = localStorage.getItem(HISTORY_KEY);
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const [currentHistoryIndex, setCurrentHistoryIndex] = useState(() => {
        const savedIndex = localStorage.getItem(HISTORY_INDEX_KEY);
        return savedIndex ? JSON.parse(savedIndex) : 0;
    });

    useEffect(() => {
        localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
        localStorage.setItem(
            HISTORY_INDEX_KEY,
            JSON.stringify(currentHistoryIndex)
        );
    }, [history, currentHistoryIndex]);

    const updateHistory = (newNodes: any, newEdges: any) => {
        const newHistory = [
            ...history.slice(0, currentHistoryIndex + 1),
            { nodes: newNodes, edges: newEdges },
        ];
        if (newHistory.length > MAX_HISTORY) {
            newHistory.shift();
        }
        setHistory(newHistory);
        setCurrentHistoryIndex(newHistory.length);
    };

    const undo = () => {
        if (currentHistoryIndex > 0) {
            const newHistoryIndex =
                currentHistoryIndex > 1
                    ? currentHistoryIndex - 2
                    : currentHistoryIndex - 1;
            setCurrentHistoryIndex(currentHistoryIndex - 1);
            const { nodes: prevNodes, edges: prevEdges } =
                history[newHistoryIndex];
            const { nodes = [], edges = [] } =
                currentHistoryIndex === 1
                    ? template || {}
                    : { nodes: prevNodes, edges: prevEdges };
            setNodes(nodes);
            setEdges(edges);
            dispatch(updateEdges(edges));
            dispatch(updateNodes(nodes));
        }
    };
    const redo = () => {
        if (currentHistoryIndex < history.length) {
            const newHistoryIndex = currentHistoryIndex;
            setCurrentHistoryIndex(newHistoryIndex + 1);
            const { nodes: nextNodes, edges: nextEdges } =
                history[newHistoryIndex];
            const { nodes = [], edges = [] } = {
                nodes: nextNodes,
                edges: nextEdges,
            };
            setNodes(nodes);
            setEdges(edges);
            dispatch(updateEdges(edges));
            dispatch(updateNodes(nodes));
        }
    };

    const handleClean = () => {
        setNodes([]);
        setEdges([]);
    };

    const onNodesDelete = (deleted: any) => {
        const deleteId = deleted?.length ? deleted[0]?.id : "";
        const newNodes = nodes.filter((item) => item.id !== deleteId);
        const newEdges = edges.filter(
            (edge) =>
                edge.source !== String(deleteId) &&
                edge.target !== String(deleteId)
        );
        updateHistory(updateNodes, edges);
        dispatch(updateEdges(newEdges));
        dispatch(updateNodes(newNodes));
    };

    const [copiedNode, setCopiedNode] = useState<any>();

    const handleKeyDown = useCallback(
        (event: any) => {
            const target = event.target as HTMLElement;
            if (
                target.tagName === "INPUT" ||
                target.tagName === "TEXTAREA" ||
                target.isContentEditable
            ) {
                return; // Skip handling Backspace/Delete inside input or editable fields
            }
            if (event.key === "Backspace" || event.key === "Delete") {
                setNodes((nodes) => nodes.filter((node) => !node.selected));
                const selectedId = nodes.find((node) => node?.selected)?.id;
                const updatedEdges = edges.filter(
                    (edge) =>
                        edge.source !== selectedId && edge.target !== selectedId
                );
                setEdges(updatedEdges);
                dispatch(updateEdges(updatedEdges));
                dispatch(updateNodes(nodes.filter((node) => !node.selected)));
            }
            if (event.ctrlKey || event.metaKey) {
                switch (event.key) {
                    case "c": // Ctrl + C -> Copy
                        if (nodes.length > 0) {
                            const selectedNode = nodes.find(
                                (node) => node.selected
                            );
                            if (selectedNode) {
                                setCopiedNode(selectedNode);
                            }
                        }
                        break;
                    case "v": // Ctrl + V -> Paste
                        if (copiedNode) {
                            const newNode = {
                                ...copiedNode,
                                selected: false,
                                id: Math.floor(
                                    (1 + Math.random()) * 0x10000
                                ).toString(),
                                position: {
                                    x:
                                        copiedNode?.position?.x +
                                        +copiedNode?.width +
                                        10,
                                    y:
                                        copiedNode?.position?.y +
                                        +copiedNode?.height +
                                        10,
                                },
                            };
                            setNodes((nds) => [...nds, newNode]);
                            dispatch(updateNodes(nodes.concat(newNode)));
                        }
                        break;
                    default:
                        break;
                }
            }
        },
        [nodes, copiedNode]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const onNodeClick = useCallback(
        (_: any, node: any) => {
            addBorder(node);
        },
        [setNodes]
    );

    const handlePaneClick = () => {
        setNodes((nds) =>
            nds.map((n) => ({
                ...n,
                style: {
                    ...n.style,
                    backgroundColor: "",
                    outline: "1px solid transparent",
                },
            }))
        );
    };

    return (
        <div className="providerflow">
            {isLoading || !id ? (
                <Spin
                    style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                />
            ) : (
                <>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            nodeTypes={nodeHandlers}
                            onDrop={onDrop}
                            onNodeDragStop={onNodeDragStop}
                            onNodeDragStart={onNodeDragStart}
                            onDragOver={onDragOver}
                            onReconnect={onReconnect}
                            onReconnectStart={onReconnectStart}
                            onReconnectEnd={onReconnectEnd}
                            onNodesDelete={onNodesDelete}
                            onNodeClick={onNodeClick}
                            onPaneClick={handlePaneClick}
                            fitView
                        >
                            <Controls />
                        </ReactFlow>
                    </div>
                    <Background size={2} gap={8} color={Colors.MainDark50} />
                </>
            )}
            <ControlPanel
                undo={undo}
                redo={redo}
                index={currentHistoryIndex}
                clean={handleClean}
            />
            <TypeConnections top={40} />
            <Sidebar />
        </div>
    );
};

type Connector = { id: number; connection_type_id: number };

const connection = (
    params: { sourceHandle: string; targetHandle: string },
    data: any[]
) => {
    const { sourceHandle, targetHandle } = params;
    const allConnect: Connector[] = [];

    data.forEach((item) => {
        if (item?.connectors?.length) {
            allConnect.push(...item.connectors);
        }
        if (item?.node?.connectors?.length) {
            allConnect.push(...item.node.connectors);
        }
    });

    const sourceConnect = allConnect.find(
        (item) => item.id === +sourceHandle
    )?.connection_type_id;
    const targetConnect = allConnect.find(
        (item) => item.id === +targetHandle
    )?.connection_type_id;
    return {
        sourceConnect,
        targetConnect,
    };
};

const createNodeHandlers = (data: Device[], template: Template) => {
    const customNodes = data?.reduce(
        (acc: Record<string, (props: any) => JSX.Element>, item: any) => {
            acc[item?.id] = (props: any) => (
                <CustomNodeHandle data={item} props={props} />
            );
            return acc;
        },
        {}
    );
    const projectNodes = template?.nodes?.reduce(
        (acc: Record<string, (props: any) => JSX.Element>, item: INode) => {
            acc[item?.type] = (props: any) => (
                <CustomNodeHandle
                    data={{
                        ...item?.node,
                        width: item?.width,
                        height: item?.height,
                        ...item,
                    }}
                    props={props}
                />
            );
            return acc;
        },
        {}
    );

    return { ...customNodes, ...projectNodes };
};
