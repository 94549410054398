import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { IStoreProject } from "shared/interfaces";

const initialState: IStoreProject<any> = {
    project: {},
    temporaryProject: {},
    fetchStatus: "init",
    fetchError: null,
    simulation: null,
    publish: 0,
    saved: true,
    favorite: false,
};

export const updateProject = createAsyncThunk(
    "project/update",
    async (values: any, { rejectWithValue }) => {
        try {
            const req = await axios.put(
                ENDPOINTS.projectById(values?.id),
                values
            );
            return req;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const createProject = createAsyncThunk(
    "dashboard/add",
    async (data: any, { rejectWithValue }) => {
        const values = {
            name: "New project",
            template: null,
            favorite: 0,
            state_template: 0,
            description: "",
            hide: 0,
            ...data,
        };
        try {
            const req = await axios.post(ENDPOINTS.projects(), values);
            return req;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProject = createAsyncThunk(
    "project/getData",
    async (id: string | number, { rejectWithValue }) => {
        try {
            const res = await axios.get(ENDPOINTS.projectById(id));
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getProjectByToken = createAsyncThunk(
    "project/ByToken",
    async (token: string, { rejectWithValue }) => {
        try {
            const res = await axios.post(ENDPOINTS.projectByToken(token));
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const updateFavourites = createAsyncThunk(
    "project/update",
    async (values: { id: number; favorite: number }, { rejectWithValue }) => {
        const { id, favorite } = values || {};
        try {
            if (favorite === 1) {
                const req = await axios.post(ENDPOINTS.projectFavorites(), {
                    favorite: favorite,
                    project_id: id,
                });
                return req.data;
            } else {
                const req = await axios.delete(
                    ENDPOINTS.projectFavoritesById(id)
                );
                return req.data;
            }
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        updateSimulation: (state) => {
            state.simulation = !state.simulation;
        },
        updatePublish: (state) => {
            state.simulation = !state.simulation;
        },
        updateShema: (state, { payload }) => {
            state.saved = false;
            console.log(111, payload);
            state.temporaryProject = payload;
        },
        updateEdges: (state, { payload }) => {
            state.saved = false;
            state.temporaryProject.template.edges =
                state.temporaryProject.template.edges = payload;
        },
        updateNodes: (state, { payload }) => {
            state.saved = false;
            if (!state.temporaryProject) {
                state.temporaryProject = {};
            }
            if (!state.temporaryProject.template) {
                state.temporaryProject.template = {};
            }
            state.temporaryProject.template.nodes = payload;
        },
        updateSaved: (state, { payload }) => {
            state.saved = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProject.pending, (state) => {
                state.fetchStatus = "fetching";
            })
            .addCase(getProject.fulfilled, (state, { payload }) => {
                state.fetchStatus = "success";
                state.project = payload?.data;
                state.temporaryProject = payload?.data;
                state.favorite = payload?.data?.is_favorite;
            })
            .addCase(getProject.rejected, (state, { error }) => {
                state.fetchStatus = "error";
                //@ts-ignore
                state.fetchError = error;
            })

            .addCase(getProjectByToken.pending, (state) => {
                state.fetchStatus = "fetching";
            })
            .addCase(getProjectByToken.fulfilled, (state, { payload }) => {
                state.fetchStatus = "success";
                state.project = payload?.data;
                state.temporaryProject = payload?.data;
            })
            .addCase(getProjectByToken.rejected, (state, { error }) => {
                state.fetchStatus = "error";
                //@ts-ignore
                state.fetchError = error;
            });
    },
});

export const {
    updateSimulation,
    updateShema,
    updateEdges,
    updateNodes,
    updateSaved,
} = projectSlice.actions;

export const selectProject = (state: RootState) => state.project;

export default projectSlice.reducer;
